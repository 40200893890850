/* ESTILOS PERSONALIZADOS - FORA DO TEMA PRINCIPAL */

.ant-descriptions-item-label {
  text-decoration: underline;
  font-weight: 500;
}

.gx-customizer-option {
  top: 380px !important;
}

/* PDF render custom styles*/

.react-pdf__Page {
  overflow: auto;
  border: 1px solid black;
  border-radius: 6px;
}

.react-pdf__Page__canvas {
  max-height: 60vh;
  object-fit: contain;
  margin: auto;
}

.pointer {
  cursor: pointer;
}

/* IntlMessages */
.ant-form-item-explain > div[role='alert'] {
  color: #f5222d;
}
